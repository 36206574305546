<template>
	<div id="app" class="sm:flex flex-grow justify-center items-center w-full min-w-screen h-full bg-white font-inter min-h-screen">
		<header class="flex items-center bg-gray-100 sm:hidden p-5">
			<img src="@/assets/img/logo.png" class="mr-4" alt="" width="60" />
			<p class="text-center uppercase text-xs">Портал независимой оценки качества условий оказания услуг медицинскими организациями</p>
		</header>
		<img id="specialButton" style="cursor: pointer" src="https://lidrekon.ru/images/special.png" alt="ВЕРСИЯ ДЛЯ СЛАБОВИДЯЩИХ" title="ВЕРСИЯ ДЛЯ СЛАБОВИДЯЩИХ" />
		<router-view />
	</div>
</template>

<script>
import '@/plugins/uhpv-full.min';

export default {
	name: 'App'
};
</script>
