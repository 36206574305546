import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
	{
		path: '/sitehtml(.*)*',
		name: 'Questionnaire3',
		meta: { title: 'Анкета' },
		component: () => import('../views/Questionnaire/index.vue')
	},
	{
		path: '/staticmojustank/:idorg',
		name: 'Questionnaire2',
		meta: { title: 'Анкета' },
		component: () => import('../views/Questionnaire/index.vue')
	},
	{
		path: '/staticogvjustank/:id/:x',
		name: 'QuestionnaireRegion2',
		meta: { title: 'Анкета' },
		component: () => import('../views/Questionnaire/index.vue')
	},
	{
		path: '/staticogvjustank/:id',
		name: 'QuestionnaireRegion2',
		meta: { title: 'Анкета' },
		component: () => import('../views/Questionnaire/index.vue')
	},
	{
		path: '/home/justank/:type/:id/:idorg',
		name: 'Questionnaire2',
		meta: { title: 'Анкета' },
		component: () => import('../views/Questionnaire/index.vue')
	},
	{
		path: '/home/justank/:type/:id',
		name: 'QuestionnaireRegion2',
		meta: { title: 'Анкета' },
		component: () => import('../views/Questionnaire/index.vue')
	},
	{
		path: '/:type/:id/:idorg',
		name: 'Questionnaire',
		meta: { title: 'Анкета' },
		component: () => import('../views/Questionnaire/index.vue')
	},
	{
		path: '/:type/:id',
		name: 'QuestionnaireRegion',
		meta: { title: 'Анкета' },
		component: () => import('../views/Questionnaire/index.vue')
	},
	{
		path: '/:id',
		name: 'QuestionnaireOut',
		meta: { title: 'Анкета' },
		component: () => import('../views/QuestionnaireAnket/index.vue')
	}
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

export default router;
