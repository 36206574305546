import Axios from 'axios';
import Vue from 'vue';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import Autocomplete from 'v-autocomplete';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import './assets/tailwind.css';
import 'v-autocomplete/dist/v-autocomplete.css';

Vue.use(Toast, {
	transition: 'Vue-Toastification__bounce',
	maxToasts: 10,
	newestOnTop: true
});

// name is optional
Vue.use(VueLodash, { name: 'custom', lodash });
Vue.use(Autocomplete);
Vue.config.productionTip = false;
Vue.prototype.$http = Axios;
Vue.prototype.$http.defaults.baseURL = 'https://anketapi.minzdrav.gov.ru/';

new Vue({
	router,
	render: (h) => h(App)
}).$mount('#app');
